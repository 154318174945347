.page {
    padding-top: 60px;
    @media(min-width: $medium) {
        padding-top: 100px;
        
    }
}

.animated-header {
    position: relative;
    z-index: 2;
}

.animated-header, .header-status-enter {
    transform: translateY(-150px);
}

.header-status-enter-active {
    transition: 500ms;
    transform: translateY(0);
}

.header-status-enter-done {
    transform: translateY(0);
}

.header-status-exit {
    transform: translateY(0);
}

.header-status-exit-active {
    transition: 500ms;
    transform: translateY(-150px);
}

.header-status-exit-done {
    transform: translateY(-150px);
}


$slideSpeed: 1150ms;
$opacity: 750ms;
//Route
#slide-router-wrap {
    
    .route-transition-enter{
        transition : left $slideSpeed, opacity $opacity!important;
        opacity: 0!important;
    }
    
    .route-transition-enter-active{
        transition : left $slideSpeed, opacity $opacity!important;
        opacity: 1!important;
    }


    .route-transition-exit{
        transition : left $slideSpeed, opacity $opacity!important;
    }
    .route-transition-exit-active{
        transition : left $slideSpeed, opacity $opacity!important;
        opacity: 0!important;
    }

    .pagewrap {
        width: 100%; 
    }
        

    //   .route-transition-exit{
    //     position: relative;
    //     top: 0;
    //     left: 0;

    //   }
    //   .route-transition-exit-active{
    //     position: relative;
    //     top: 0;
    //     left: 0;
    //   }

}


.result-loading {
    padding-top: 0;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}