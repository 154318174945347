.c-footer {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: $contrast;
}

.c-footer__inner {
    // @media(min-width: $medium) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    // }
}

.c-footer__col {
    overflow: hidden;
    margin-bottom: 30px;
    a {
        &:visited, & {
            color: #fff;
        }
        &:hover {
            text-decoration: none;
        }
    }
    p {
        max-width: 350px;
        margin: 0;
        font-size: 13px;
    }
    @media(min-width: 830px) {
        flex: 0 0 40%;
        display: flex;
        align-items: flex-end;
    }
    &:last-child {
        display: flex;
        justify-content: flex-end;
        flex: 0 0 100%;
        @media(min-width: 830px) {
            flex: 0 0 60%;
        }
    }
    
}
.c-footer__credits {
    margin-left: 20px;
}
.meta {
    @media(min-width: 480px) {
        display: flex;
        margin-top: auto;
    }
}
.c-meta-link {
    position: relative;
    z-index: 1;
    display: block;
    margin-bottom: 10px;
    margin-right: 20px;
    
    &:visited, & {
        color: #fff;
        text-decoration: underline;
    }
    &:hover, &:active {
        text-decoration: none;
    }
    @media(min-width: $medium) {
        align-self: flex-end;
        margin-bottom: 0;
    }
}


.footer-subscribe-wrapper {
    // margin: 30px auto 50px;    
    max-width: 1120px;
}

.footer-subscribe {
    background-color: #000822;
    padding: 40px 0 60px;
    max-width: 620px;
    h2 {
        margin: 0;
        font-size: 24px;
        @media(min-width: $medium) {
            font-size: 34px;
            
        }
    }
    p {
        margin: .5em 0;
        font-size: 14px;
        @media(min-width: $medium) {
            font-size: 18px;
        }
    }

    .footer-subscribe-error, .footer-subscribe-success {
        color: #f03d3d;
        font-size: 18px;
        display: block;
        margin: 0 0 .75em;
    }

    .footer-subscribe-success { 
        background-color: #64BC5A;
        color: #FFF; 
        margin: .75em 0;
        padding: .75em 1em;
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        font-size: 22px;
        svg {
            width: 24px;
            height: auto;
            margin-right: .5em;
            path {
                fill: #FFF;
            }
        }
    }

    .email-input {
        margin: 2em 0 0;
    }
    
    input[type="email"] {
        margin: 0 0 1em;
        border-radius: 50px;
        font-size: 18px;
        border: 1px solid #FFF;
        background-color: #000822;
        max-width: 520px;
        width: 100%;
        display: flex;
        align-items: center;
        height: 60px;
        padding: 0 25px;
        font-family: Graphik;
        color: #FFF;
        outline: 0;
        &::placeholder { color: rgba(#FFF, .75); }
        &.has-error {
            border-color: #f03d3d;
        }
    }

    button {
        border: 0;
        padding: 0;
        background-color: transparent;
        color: #FFF;
        font-family: Arial;
        font-weight: bold;
        margin: 1.5em 0 0;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        @media(min-width: $medium) {
            font-size: 22px;
        }
        &:disabled {
            opacity: .5;
            cursor: not-allowed;
        }
        svg {
            margin: 0 .5em 0 0;
            width: 35px;
            height: auto;
            transition: 250ms;
        }
        &:hover {
            svg { transform: translateX(7px); }
        }
    }

    .input-check {
        margin-bottom: 6px;
        input { position: absolute; pointer-events: none; opacity: 0; z-index: -1;}
        input:checked {
            & + label:after {
                transform: scale(1);
                opacity: 1;
            }
        }
        label {
            display: flex;
            position: relative;
            cursor: pointer;
            &:before, &:after {
                // border-radius: 50%;
                content: '';
                display: block;
            }
            &:before {
                flex: 0 0 20px;
                width: 20px;
                height: 20px;
                border: 1px solid #FFF;
                margin: 0 .75em 0 0;
            }
            &:after {
                position: absolute;
                top: 4px;
                left: 4px;
                background-color: #FFF;
                width: 12px;
                height: 12px;
                transform: scale(0);
                transition: 250ms;
            }
            &:hover:after {
                transform: scale(1);
                opacity: .75;
            }

            a:hover {
                text-decoration: none;
            }
        }
        
    }
}