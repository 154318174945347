.c-question__head {
    @media(min-width: $large) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.c-question__head__inner {
    display: flex;
    align-items: center;
}

.c-question__description {
    display: block;
    font-weight: 500;
    margin: 0.5em 0 2px;
    max-width: 800px;
    font-size: 18px;
    @media(min-width: $medium) {
        font-size: 20px;
    }
    @media(min-width: $large) {
        margin: 0.5em 0 -50px;
        font-size: 28px;
    }
}

.c-question__moreinfo {
    margin-left: 5px;
    display: block;
    cursor: pointer;
    position: relative;

    &:after {
        content: '';
        background-image: url('/media/images/ic-info.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 4px;
        top: -14px;
        width: 22px;
        height: 22px;
        @media(min-width: $medium) {
            width: 32px;
            height: 32px;
        }
    }
}

.c-question-answer-text {
    flex: 1;
    font-size: 15px;
    @media(min-width: 950px) {
        display: block;
        margin-top: 20px;
        max-width: 200px;
    }
}