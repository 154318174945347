.c-btn, select {
    background-color: #fff;
    color: $contrast;
    text-decoration: none;
    border-radius: 50px;
    font-size: 22px;
    font-weight: 500;
    padding: 0.7em 1.2em;
    display: inline-flex;
    align-items: center;
    font-family: $font-alt;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: $transition;
    &:hover {
        background-color: rgb(211, 211, 211);

    }
    span {
        margin-right: 8px;
        &:last-child {
            margin-right: 0;
        }
    }
    svg {
        height: auto;
        width: 30px;
        margin-top: -2px;
    }
    @media(min-width: $medium) {
        font-size: 26px;
        padding: 1em 1.5em;
    }
}
.c-btn-home {
    svg {
        margin-right: -2px;
        transition: $transition;
    }
    &:active, &:hover {
        background-color: #fff;
    }
    @media(min-width: $medium) {
        &:hover {
            svg { transform: translateX(5px); }
        }
    }
    &__loader {
        display: none;
        svg {
            width: 30px;
            path { stroke: $contrast; }
        } 
    }
    &--loading {
        .c-btn-home__loader {
            display: block;
            @media(min-width: $medium) {
                display: none;
            }
        }
        
        .c-btn-home-arrow { 
            display: block;
        }
    }
}

select {
    font-size: 16px;
    font-weight: 400;
    padding-right: 2.5em;
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-image: url('/media/images/ic-chevron-down-dark.svg');
    background-position: calc(100% - 0.5em) center;
    @media(min-width: $medium) {
        padding-right: 3em;
        background-position: calc(100% - 1em) center;
    }
}
.c-btn-holder {
    display: flex;
    justify-content: center;
    pointer-events: none;
    &--home {
        margin-top: 30px;
        position: relative;
    }
    &--footer {
        margin-top: -70px;
        margin-bottom: 40px;
        
    }
    .c-btn {
        pointer-events: all;
    }
}
.c-btn--arrow {
    margin-top: 10px;
}
.c-btn--fixed, .c-btn--arrow {
    margin: 0;
    z-index: 100;
    padding-right: 2.5em;
    background-image: url('/media/images/ic-arrow-right.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 1em) center;
    @media(min-width: $medium) {
        padding-right: 3em;
        background-position: calc(100% - 1.5em) center;
        &:hover {
            background-position: calc(100% - 1.25em) center;
        }
    }
  
}

.c-btn--fixed {
    position: fixed;
    bottom: 25px; 
    left: 50%;
    transform: translateX(-50%);
}

.c-btn-add {
    background-color: transparent;
    text-decoration: underline;
    font-size: 18px;
    padding: 0.5em 0;
    margin: 12px 0;
    display: inline-block;
    font-weight: 500;
    border: 0;
    outline: none;
    cursor: pointer;
    color: #fff;
    &:hover {
        background-color: transparent;
        color: #fff;
    }
}

.c-btn--back {
    background-repeat: no-repeat;
    background-image: url('/media/images/ic-arrow-left.svg');
    background-position: center;
    background-color: #fff;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    padding: 0;
    margin-right: auto;
    span {
        display: none;
    }
}

.c-result-body {
    .c-btn--back {
        float: right;
    }
}

.c-btn--small {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    margin-top: 70px;
    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/media/images/ic-mail.svg');
        margin: 0 15px 0 -10px;
    }
}