.c-result + .c-result {
    margin-top: 10px;
}
.c-result-body {
    .o-content {
        max-width: 720px;
    } 
}
.c-result__item {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    justify-content: space-between;
    padding: 22px 20px;
    font-weight: 500;
    color: $contrast;
    margin: 0 -20px;
    transition: $transition;
    &:hover {
        cursor: pointer;
        background-color: rgb(211, 211, 211);
    }
    @media(min-width: $medium) {
        margin: 0;
        padding: 22px 30px;
    }
    @media(min-width: 950px) {
        font-size: 20px;
    }
    @media(min-width: $xl) {
        font-size: 26px;
    }
    & + .c-result__item {
        margin-top: 7px;
    }
   
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 0;
        margin-top: 7px;
        padding: 0;
        padding-left: 20px;
        
        li {
            color: #7F8390;
            text-transform: uppercase;
            font-size: 12px;
            margin: 3px;
            font-weight: 400;
            
            &:after {
                content: ','
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            @media(min-width: 950px) {
                font-size: 14px;
                padding: 0;
            }
            @media(min-width: $xl) {
                font-size: 20px;
            }
        }
    }
}

.c-result__star {
    font-size: 40px;
    line-height: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
}

.c-result__star-wrapper {
    height: 0;
    position: relative;
    width: 40px !important;
    min-width: none;
    margin: 0 !important;
}

.c-result-flex-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 15px;
    @media(min-width: 1050px) {
        flex-direction: row;
        align-items: center;
        margin-top: 0;
    }
}

.c-table-image {
    max-width: 100%;
}
.c-chart {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    position: relative;
    tr {
        td {
            padding: 0;
        }   
    }
}
.c-chart__index { 
    position: absolute; 
    left: -1.8rem;
    span {
        &:before {
            content: attr(data-index);
            color: #9B9B9B;
        }
    }
}
.c-chart-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 50px;
    justify-content: space-between;
    padding: 22px 20px;
    font-weight: 500;
    color: $contrast;
    margin: 0 -20px;
    margin-bottom: 7px;
    @media(min-width: $medium) {
        margin: 0 0 7px;
        padding: 22px 30px;
    }
}
.c-chart__title { 
    flex: 0 0 100%;
    @media(min-width: 950px) {
        flex: 0 0 40%;
        font-size: 20px; 
    }
    @media(min-width: $xl) {
        font-size: 26px;
    }
}
.c-chart__percentage-label { 
    flex: 0 0 20%;
    color: #7F8390;
    order: 3;
    font-weight: 400;
    text-align: right;
    @media(min-width: 950px) {
        font-size: 14px;
    }
    @media(min-width: $xl) {
        font-size: 20px;
    }
}
.c-chart__percentage {
    flex: 0 0 40%;
    @media(min-width: 950px) {
        font-size: 16px; 
    }
    div {
        background-color: $contrast;
        height: 9px;
        border-radius: 5px;
    }
}

.c-chart-row:nth-child(1n+4) {
    display: none;
    .show-all-results & {
        display: flex;
    }
}
.c-result-party {
    padding: 30px 0 20px;
    border-top: 1px solid #393CDD;
    position: relative;
    overflow: hidden;
    h2 {
        margin: 0;
        font-weight: 500;
        font-size: 20px; 
        color: #000;
        @media(min-width: $medium) {
            font-size: 26px;
        }
    }
    &+.c-result-party { margin-top: 10px;}
}

.c-result-description-title {
    color: #393CDD;
    display: block;
    font-size: 14px;
    margin-bottom: 6px;
    margin-top: 4px;
    @media(min-width: $medium) {
        font-size: 16px; 
    }
}
.c-result-description-text {
    color: #393CDD;
}
.result-loading {
    svg {
        max-width: 160px;
        @media(min-width: $medium) {
            max-width: 300px;
        }
    }
}