.readspeaker {

    --width: 34px;
    --fontSize: 14px;
    @media(min-width: $medium) {
        --width: 54px;
        --fontSize: 18px;
    }

    position: fixed;
    top: 30px;
    right: 30px;

    button {
        background-color: transparent;
        padding: 0;
        border: 0;
        outline: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        svg { 
            width: var(--width); 
            flex: 0 0 var(--width); 
            height: auto; 
        }
        span { 
            font-family: "Graphik", Arial, sans-serif;
            color: #FFF; 
            margin-left: .75em; 
            font-size: var(--fontSize);
        }

        &:hover {
            svg {
                circle { fill: #000822; }
                polygon, rect { fill: #FFF; }
                .rs-icon { fill: #FFF;}
            }
        }
    }
    
}

.c-popup {
    .readspeaker {
        right: 70px;
        top: 12px;
        position: absolute;
        @media(min-width: $medium) {
            right: 100px;
            top: 22px;    
        }
        
        button {
            --width: 36px;
            border-radius: 50%;
            border: 2px solid #3742C8;
            @media(min-width: $medium) {
                --width: 56px;                
            }
            
            span { display: none; }
            svg g {
                fill: #3742C8;
            }
            &:hover {
                border-color: #25259a;
                svg circle { fill: #25259a; }
                .rs-icon { fill: #FFF; }
            }
        }
    }
    
}

.rs-start {
    // --width: 32px;
}