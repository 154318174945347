.c-about {
    &__intro {
        margin-top: -1em;
        margin-bottom: 30px;
        font-size: 22px;
        font-weight: 500;
        @media(min-width: $medium) {
            font-size: 28px;
            max-width: 680px;
            margin-bottom: 50px;
            margin-top: -0.5em;
        }
    }
    &__controls {
        margin-bottom: 30px;
        @media (min-width: $large) {
            margin-bottom: 34px;
            select {
                width: 48%;
            }
        }
        h2 {
            margin-bottom: 10px;
        }
        
        @media (min-width: $large) {
            display: flex;
            align-items: center;
            .parts {
                flex: 0 0 50%;
            }
            h2.parts {
                flex: 0 0 22%;
                margin: 0;
            }
        }
    }
}
.c-radio-group {
    display: flex;
    align-items: center;
    margin: 0 -1em;
    @media (min-width: $large) {
        margin: 0 -4em;
    }
    label {
        margin: 0 1em;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media (min-width: $large) {
            margin: 0 4em;
        }
        .faux-radio {
            display: block;
            width: 20px;
            height: 20px;
            background-color: #fff;
            border-radius: 50%;
            position: relative;
            margin-right: 1em;
            @media (min-width: $large) {
                width: 40px;
                height: 40px;
            }
            &:after {
                content: '';
                background-color: $contrast;
                width: 14px;
                height: 14px; 
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0) scale(0);
                opacity: 0;
                transition: $transition;
                @media (min-width: $large) {
                    width: 28px;
                    height: 28px;
                }
            }
            
        }
        &:hover {
            .faux-radio:after {
                @media(min-width: $xl) {
                    opacity: 0.4;
                    transform: translate3d(-50%, -50%, 0) scale(0.7);
                }
            }
        }
        input {
            opacity: 0;
            position: absolute;
        }
    }
    input:checked + .faux-radio:after {
        opacity: 1;
        transform: translate3d(-50%, -50%, 0) scale(1);
    }
}