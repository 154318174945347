.c-home {
    margin-top: 0px;
    @media (min-width: $medium) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -40px;
    }
    @media (min-width: $xxl) {
        margin-top: 0;
    }
    &__intro {
        font-size: 23px;
        font-weight: 500;
        max-width: 480px;
        @media(min-width: $medium) {
            flex: 0 0 50%;
            max-width: 620px;
            padding-right: 40px;
            font-size: 27px;

        }
    }
}
.c-home__title {
    @media (max-width: 767px) {
        display: flex;
        align-items: center;
        font-size: 36px;
    }
    &__loader {
        flex: 0 0 80px;
        margin-right: 15px;
        svg {
            width: 100%;
            height: auto;
        }
        @media (min-width: $medium) {
            display: none;
        }
    }
}
.c-home__loader {
    display: none;
    @media (min-width: $medium) {
        display: block;
        flex: 0 0 50%;
    }
    > div {
        margin-top: 120px;
        @media (min-width: $xl) {   
            margin-top: 50px;
        }
    }
    svg {
        width: 100%;
        max-width: 60%;
        height: auto;
        @media (min-width: $large) {   
            max-width: 378px;
            margin-left: 40px;
        }
        @media (min-width: $xxl) {
            max-width: 90%;
        }
    }

}