.c-popup {
    position: fixed;
    top: 0;
    left: 0; 
    right: 0;
    z-index: 100;
    background-color: rgba($contrast, .75);
    height: 100%;
    overflow-y: scroll;
    padding: 3rem 15px;
}

.c-popup__inner {
    position: relative;
    top: 50vh;
    max-width: 830px;
    margin: -30vh auto 0;
    padding: 1px 20px 30px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    background-color: #FFF;
    width: 100%;

    @media(min-width: $large) {
        padding: 1px 50px 30px;
    }
    h2 {
        font-weight: normal;
        font-size: 20px;
        @media(min-width: $large) {
            font-size: 23px;
        }
        &+p {
            margin-top: 2px;
        }
    }
    figure {
        margin: 30px -20px;
        @media(min-width: $large) {
            margin: 30px -50px;
        }
        img {
            max-width: 100%;
            width: 100%;
        }
    }
}

.c-popup__desc {
    color: #393CDD;
    font-size: 15px;
    line-height: 1.4666666667;
    word-break: break-word;
    a {
        font-weight: 500;
        &:visited, & {
            color: $primary-color;
        }
        &:hover {
            text-decoration: none;
        }
    }
}

.c-popup-title {
    font-size: 28px;
    padding-right: 60px;
    margin: 2.4rem 0 1.4rem;
    color: $contrast;
    
    @media(min-width: $large) {
        font-size: 56px;
        margin: 3.4rem 0 2.3rem;
        &--newsletter {
            font-size: 44px;
        }
    }
}

.c-popup__close {
    position: absolute;
    right: 20px;
    top: 12px;
    width: 40px;
    height: 40px;
    background-color: $primary-color;
    cursor: pointer;
    background-image: url('/media/images/ic-close.svg');
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    border: 0;
    outline: 0;
    @media(min-width: $large) {
        top: 20px;
        width: 60px;
        height: 60px;
        background-size: 20px;
    }
    &:hover {
        background-color: darken($primary-color, 15%);
    }
}

.c-result-all-parties {
    
    background-color: transparent;
    border: 0;
    color: #292929;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    margin-top: 22px;
    margin-bottom: 18px;
}

.c-result-party {
    p {
        font-size: 15px;
        line-height: 1.4666666667;
        color: $contrast;
    }
}
