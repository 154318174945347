@font-face {
	font-family: 'Graphik';
	src: url('fonts/Graphik-Light.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('fonts/Graphik-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('fonts/Graphik-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('fonts/Graphik-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}