
.c-checkbox, .c-radio {
    label {
        position: relative;
        display: flex;
        cursor: pointer;
        transition: $transition-slower;
        @media(min-width: $medium) {
            align-items: center;
        }
        .toggle {
            width: 60px;
            display: block;
            position: relative;
            width: 70px;
            height: 36px;
            border: 4px solid #fff;
            border-radius: 30px;
            background-color: #fff;
            margin-right: 20px;
            &:after {
                content: '';
                border-radius: 50%;
                position: absolute;
                left: 0;
                width: 28px;
                height: 28px;
                background-color: #DFDFDF;
                transition: $transition-slower;
            }
        }
    }
    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
}
.answered span.toggle:after,
.c-checkbox input:checked + label span:after,
.c-radio input:checked + label span:after {
    left: calc(100% - 28px);
    background-color: $contrast;
}
.c-checkbox input:checked + label,
.c-radio input:checked + label {
    color: #a5a4a4;
}
.c-checkbox, .c-radio {
    &:hover {
        .toggle:after {
            @media(min-width: $xl) { background-color: #b4b3b3; }
        }
        input:checked + label span:after {
            background-color: $contrast;
        }
    } 
}
.c-group--alt {
    .c-radio {
        position: relative;
        // z-index: 2;
        &+.c-radio {
            margin-top: 20px;
            @media(min-width: 950px) {
                margin-top: 0;
            }
        }
        @media(min-width: 950px) {
            flex: 1;
            
            label { 
                flex-direction: column;
                align-items: center;
                padding: 0 0.5rem;
                text-align: center;
                &:before {
                    margin-right: 8px;
                }
            }
            &:first-of-type {
                label {
                    align-items: flex-start;
                    text-align: left;
                    padding-left: 0;
                    &:before {
                        margin-left: -1px;
                    }
                }
            }
            &:last-of-type {
                label {
                    align-items: flex-end;
                    text-align: right;
                    padding-right: 0;
                    &:before {
                        margin-right: -1px;
                    }
                }
            }
        }
    }
}

// .c-radio label:before { border-radius: 50%; }

.no-agreement {
    .c-question-answer-text {
        display: flex;
    }
}

.c-question-answer-text img {
    height: 2.5em;
    display: block;
}

.c-radio--list {
    &+.c-radio--list {
        margin-top: 10px;
    }
    span {
        margin: 0;
        max-width: none;
    }
}

.c-radio--newsletter {
    @media(min-width: 950px) {
        width: 33.3333%;
        display: inline-block;
    }
    .c-question-answer-text {
        font-size: 15px;
    }
}